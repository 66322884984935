.account-nav{
 justify-content: start !important;
}
.backdrop{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    background-color: black;
    inset: 3;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}