.text-red {
    color: #FF5733;
}

.bootstrap-icon-picker li {
    list-style: none;
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
}

.bootstrap-icon-picker li .MuiFormControlLabel-root {
    margin: 0 !important;
}

.bootstrap-icon-picker li .bi {
    font-size: 24px;
}