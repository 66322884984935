.Inboxname {
    padding-left: 5%;
    margin-top: 5%;
    display: flex;
    margin-right: 10%;
    margin-left: 10%;
}

.Phonenumberid {
    padding-left: 5%;
    margin-top: 2%;
    display: flex;
    margin-right: 10%;
    margin-left: 10%;
}

.phoneinput {
    margin-top: 8px;
}

.childdiv {
    margin-right: 20px;
}