.voiceinboxname {
    padding-left: 5%;
    margin-top: 5%;
    display: flex;
    margin-right: 20%;
    margin-left: 10%;


}

.iconpickervoice {
    margin-left: 20px;
    margin-right: 20px;
}

.phonenumbervoice {
    width: 400px;
    margin-top: -17px;
}

.loadingbutton {
    display: flex;
    justify-content: center;
    margin-top: 10%;

}

.secondfbdiv {
    margin-left: 15%;
    margin-top: 20px;
    display: flex;
}

.subfbdiv {
    margin-right: 20px;
}