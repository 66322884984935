.video-responsive {
    margin-left: 15%;
    width: 800px;
    margin-top: 5%;
}

.wrapper {
    display: flex;
    overflow-x: auto;
}

.wrapper::-webkit-scrollbar {
    width: 0;
}

.channel-icon{
    width: 60px;
    height: 60px;
}
.channel-radio{
    padding: 30px 20px !important;
    min-height: 280px !important;
    width: 100%;
}
.channel-radio .MuiTypography-h6{
    font-size: 24px;
    padding: 20px 10px 10px 10px;
}
.channel-radio .MuiTypography-subtitle1{
    color: #212B36;
    font-size: 14px;
    font-weight: 400;
}
.channel-selected{
    position: absolute;
    display: none;
    width: 100%;
    text-align: center;
    left: 0;
    bottom: 0;
    background: #919EAB;
    padding: 5px 10px;
    color: #fff;
}

.searchWrapper{
    height: 55px;
    padding: 12px !important;
}