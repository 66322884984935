.facebookcss {
    width: 350px !important;
    background-color: #4266b2;
    border: none;
    color: white;
    font-size: 20px;
    display: flex;
    padding: 20px !important;
    border-radius: 10px !important;
    margin: 0 auto 10px auto;
}
.facebookcss:hover {
    background-color: #2065D1 !important;
}
.facebookcss p{
    padding-top: 15px !important;

}

.insta {
    width: 350px !important;
    background-color: #4266b2;
    border: none;
    color: white;
    font-size: 20px;
    display: flex;
    padding: 20px !important;
    border-radius: 10px !important;
    margin: 0 auto 10px auto;
}
.insta:hover {
    background-color: #2065D1 !important;
}
.insta p{
    padding-top: 15px !important;

}