.role-module-cards {
    padding: 10px !important
}

.maindiv {
    margin: 3%;
}

.createrolebtn {
    margin-bottom: 3%;
}