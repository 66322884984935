.smsvoice {
    padding-left: 5%;
    margin-top: 5%;
    display: flex;
    margin-right: 15%;
    margin-left: 15%;
}

.iconpickersms {
    margin-left: 20px;
    margin-right: 20px;
}

.seconddiv {
    padding-left: 5%;
    margin-top: 2%;
    display: flex;
    margin-right: 15%;
    margin-left: 15%;
}