.search-input fieldset{
    border: none !important;
}
.header-appbar{
    height: 70px !important;
    padding: 20px 50px !important
}
.dash-filter-drawer .MuiDrawer-paper{
   
    padding: 20px

}
.dash-filter-drawer .MuiBackdrop-root{
    opacity: 0 !important;
}
.desktop-width .MuiDrawer-paper{
    margin-left: 280px;
}
.normal-width .MuiDrawer-paper{
    margin-left: 0px;
}