.application-cards{
    width: 100%;
    border: 1px solid #eee;
    padding: 20px 30px;
}

.application-cards img{
    margin-bottom: 10px;
}

.application-cards h4{
    margin-bottom: 10px;
}
.application-cards .MuiTypography-caption{
    font-size: 16px;
    color: #aaa;
    margin-bottom: 10px;
}

.application_header {
    width: "100%";
    padding: 20px 20px;
    margin-top: 20px;
    background-color: "#F7F8F8";
    border-radius: 10;
    margin-bottom: 20px;
}