.inbox-hvr-show{
    width: 100px !important;
    height: 60px !important;
    padding: 10px 0 0 20px;
    display: none;
}
.MuiDataGrid-row:hover .inbox-hvr-show{
    display: block;
}

.inbox-btn-small svg{
    width: 15px !important;
    height: 15px !important;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 999;
    top: 100%;
    left: 50%;
    margin-left: -60px;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .status-card{
    width: 14px;
    height: 14px;
    border-radius: 3px;
    display: inline-block;
  }
  .status-card.status-red{
    background-color: #00AB55;
  }

  .status-card.status-green{
    background-color: #FF3030;
    
  }

  .inbox-filter .MuiListItemText-root{
    padding-right: 120px;
  }

  .callmatrics-timeline .MuiTimelineItem-root::before{
    display: none;
  }
  .hyperlink{
    text-decoration: none;
    color: #2065D1;
    cursor: pointer;
  }

  .hyperlink:hover{
    text-decoration: none;
    color: #0C53B7;
    cursor: pointer;
  }